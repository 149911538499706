import { Row, Col, Card } from '../index';

const cardLayout = {
  xs: { span: 22, offset: 1 },
  sm: { span: 10, offset: 7 },
  style: { marginBottom: 20 }
};

export default function CardBox({ children }) {
  return (
    <Row>
      <Col {...cardLayout}>
        <Card>
          {children}
        </Card>
      </Col>
    </Row>
  )
}